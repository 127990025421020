import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";

export default function HelmetCommon() {
  // const [isSSR, setIsSSR] = useState(true);
  // const [ckInit, setCkInit] = useState(false);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setIsSSR(false);
  //     setTimeout(() => setCkInit(true), 1000);
  //   }
  // });

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        {/* Preconnect */}
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://www.google-analytics.com" />
        <link rel="preconnect" href="https://code.jivosite.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <link rel="preconnect" href="https://mc.yandex.ru" />
        <link rel="preconnect" href="https://stats.gatsbystorefront.com" />
        <link rel="preconnect" href="https://rsms.me" />
        {/* <link rel="preconnect" href="https://fantastic-painter-7559.ck.page" />
        <link rel="preconnect" href="https://f.convertkit.com" /> */}

        {/* Preload */}
        {/* <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap"
        /> */}

        {/* Font */}
        {/* <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap"
          rel="stylesheet"
          // media="print"
          // onload="this.onload=null;this.removeAttribute('media');"
        /> */}

        <link
          rel="stylesheet"
          href="https://rsms.me/inter/inter.css"
          media="print"
          onload="this.onload=null;this.removeAttribute('media');"
        />

        {/* Favicon */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        {/* <link rel="manifest" href="/site.webmanifest" /> */}
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff" />

        {/* Author */}
        <meta name="author" content="Gatsby Storefront" />
        {/* 
        {/* Plausible */}
        <script
          async
          defer
          data-domain="gatsbystorefront.com"
          src="https://stats.gatsbystorefront.com/js/plausible.js"
        ></script>

        {/* ConvertKit */}
        {/* {!isSSR && !ckInit ? (
          <script
            defer
            data-uid="6e7a127291"
            src="https://fantastic-painter-7559.ck.page/6e7a127291/index.js"
          ></script>
        ) : (
          ""
        )}  */}
      </Helmet>
    </>
  );
}
