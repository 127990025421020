// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-headless-commerce-shopify-precision-hydration-js": () => import("./../../../src/pages/headless-commerce-shopify-precision-hydration.js" /* webpackChunkName: "component---src-pages-headless-commerce-shopify-precision-hydration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-request-a-demo-js": () => import("./../../../src/pages/request-a-demo.js" /* webpackChunkName: "component---src-pages-request-a-demo-js" */),
  "component---src-pages-shopify-headless-commerce-js": () => import("./../../../src/pages/shopify-headless-commerce.js" /* webpackChunkName: "component---src-pages-shopify-headless-commerce-js" */),
  "component---src-pages-shopify-international-ecommerce-js": () => import("./../../../src/pages/shopify-international-ecommerce.js" /* webpackChunkName: "component---src-pages-shopify-international-ecommerce-js" */),
  "component---src-pages-shopify-pwa-js": () => import("./../../../src/pages/shopify-pwa.js" /* webpackChunkName: "component---src-pages-shopify-pwa-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

