import React from "react";

// // Import Css
// import "../../Apps.scss";
// import "../../css/materialdesignicons.min.css";

// Layout Components
import Header from "../SharedTailwind/Header";
import Footer from "../SharedTailwind/Footer";
import HelmetCommon from "./HelmetCommon";

// import FooterWithoutMenu from "./FooterWithoutMenu";

function Layout(props) {
  return (
    <React.Fragment>
      <HelmetCommon />
      <Header />
      {props.children}
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
